import { createSlice } from "@reduxjs/toolkit";
import {
  getLineChartOfEarnings,
  getLineChartOfUserOverview,
  getPieChartOfAllAccounts,
  getSalesAndSummary,
} from "./DashboardApi";
const initialState = {
  isLoading: false,
  data: {},
  revenue_data: [],
  user_data: [],
  pieChartData: {
    active: 0,
    inactive: 0,
    pending: 0,
  },
  saleAndSummary: {},
  noDatafound: false,
  noUserDatafound: false,
  drawer: true,
  isError: false,
  defaultUserOption: "Month",
  defaultRevenueOption: "monthly",
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setdata: (state, action) => {
      state.data = action.payload;
    },
    setDrawerClose: (state, action) => {
      state.drawer = action.payload;
    },
    setDefaultUserOption: (state, action) => {
      state.defaultUserOption = action.payload;
    },
    setDefaultRevenueOption: (state, action) => {
      state.defaultRevenueOption = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getLineChartOfEarnings.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getLineChartOfEarnings.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action?.payload?.revenue_data && action?.payload?.revenue_data.length === 0) {
        state.revenue_data = action?.payload?.revenue_data;
        state.noDatafound = true;
      }
      state.revenue_data = action?.payload?.revenue_data;
    });
    builder.addCase(getLineChartOfEarnings.rejected, (state, action) => {
      state.isError = action.error.message;
      state.datesArray = [];
      state.revenuesArray = [];
      state.isLoading = false;
    });
    builder.addCase(getLineChartOfUserOverview.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getLineChartOfUserOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action?.payload?.user_count_data.length === 0) {
        state.user_data = action?.payload?.revenue_data;
        state.noUserDatafound = true;
      }
      state.user_data = action?.payload?.user_count_data;
    });
    builder.addCase(getLineChartOfUserOverview.rejected, (state, action) => {
      state.isError = action.error.message;
      state.datesArray = [];
      state.revenuesArray = [];
      state.isLoading = false;
    });
    builder.addCase(getPieChartOfAllAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPieChartOfAllAccounts.fulfilled, (state, action) => {
      state.pieChartData = {
        active: action.payload?.status_percentages.active,
        inactive: action.payload?.status_percentages.inactive,
        pending: action.payload?.status_percentages.pending,
      };
      state.isLoading = false;
    });
    builder.addCase(getPieChartOfAllAccounts.rejected, (state, action) => {
      state.isError = action.error.message;
      state.pieChartData = {
        active: 0,
        inactive: 0,
        pending: 0,
      };
      state.isLoading = false;
    });
    builder.addCase(getSalesAndSummary.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSalesAndSummary.fulfilled, (state, action) => {
      state.saleAndSummary = action?.payload?.revenue_data;
      state.isLoading = false;
    });
    builder.addCase(getSalesAndSummary.rejected, (state, action) => {
      state.isError = action.error.message;
      state.saleAndSummary = {};
      state.isLoading = false;
    });
  },
});

export const {
  setdata,
  setDrawerClose,
  setDefaultUserOption,
  setDefaultRevenueOption,
} = dashboardSlice.actions;
export const getData = (state) => state.dashboard.data;
export const getDrawerValue = (state) => state.dashboard.drawer;
export const getIsError = (state) => state.dashboard.isError;
export const getIsLoading = (state) => state.dashboard.isLoading;
export const getRevenueData = (state) => state.dashboard.revenue_data;
export const getPieChartData = (state) => state.dashboard.pieChartData;
export const getUserChartData = (state) => state.dashboard.user_data;
export const getNodataFound = (state) => state.dashboard.noDatafound;
export const getNoUserdataFound = (state) => state.dashboard.noUserDatafound;
export const getSalesSummary = (state) => state.dashboard.saleAndSummary;
export const getDefaultUserOption = (state) =>
  state.dashboard.defaultUserOption;
export const getDefaultRevenueOption = (state) =>
  state.dashboard.defaultRevenueOption;
export default dashboardSlice.reducer;
