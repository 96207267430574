import React, { useCallback, useEffect, useRef, useState } from "react";
import SearchIcon from "../../images/searchIcon.svg";
import { Pagination, message } from "antd";
import { DownArrow } from "../../components/svgicons";
import Drawer from "../../components/Drawer";
import Navbar from "../../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getDrawerValue, setDrawerClose } from "../Dashboard/DashboardSlice";
import { getAllUserSubscriptionsData } from "./SubcriptionSlice";
import { getAllUserSubscriptions } from "./SubscriptionApi";
import Loader from "../../loader/loader";
import { Popover } from "antd";
import "../../css/sb-admin-2.min.css";
import "./styles.scss";

const Subscriptions = () => {
  const tableRef = useRef(null);
  const hasFetchedData = useRef(false);
  const DrawerValue = useSelector(getDrawerValue);
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedPlan, setSelectedPlan] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const subscriptionsData = useSelector(getAllUserSubscriptionsData);
  const [datalaoder, setLoader] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [popoverVisibleForStatus, setPopoverVisibleForStatus] = useState(false);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [currentPage]);
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setItemsPerPage(pageSize);
    setPopoverVisible(false); // Close the plan popover
    setPopoverVisibleForStatus(false);
  };

  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
    setPopoverVisible(false);
    setCurrentPage(1); // Reset to first page when the filter changes
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setPopoverVisibleForStatus(false);
    setCurrentPage(1); // Reset to first page when the filter changes
  };

  const filteredData = subscriptionsData.filter((user) => {
    const matchesEmail = userEmail
      ? user.customer_email.toLowerCase().includes(userEmail.toLowerCase())
      : true;
    const matchesPlan =
      selectedPlan === "All" ||
      (selectedPlan === "Monthly" && user.subscription === "Monthly") ||
      (selectedPlan === "Yearly" && user.subscription === "Yearly");
    const matchesStatus =
      selectedStatus === "All" ||
      (selectedStatus === "active" && user.subscription_status === "active") ||
      (selectedStatus === "canceled" &&
        user.subscription_status === "canceled") ||
      (selectedStatus === "paused" && user.subscription_status === "paused");
    return matchesEmail && matchesPlan && matchesStatus;
  });

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const fetchAllUserSubscriptionsData = useCallback(
    async (userEmail) => {
      const actionResult = await dispatch(getAllUserSubscriptions(userEmail));
      if (getAllUserSubscriptions.rejected.match(actionResult)) {
        console.log(" actionResult.payload ", actionResult.payload);
        message.error(
          actionResult.payload ||
            "An error occurred while fetching user accounts data"
        );
      }
    },
    [dispatch]
  );

  const handleInputChange = (e) => {
    const email = e.target.value;
    setUserEmail(email);
    setLoader(true);
    setCurrentPage(1); // Reset to first page when the filter changes
  };

  useEffect(() => {
    if (!hasFetchedData.current) {
      fetchAllUserSubscriptionsData(userEmail);
      hasFetchedData.current = true;
    }
  }, [fetchAllUserSubscriptionsData, userEmail]);

  const Plans = (
    <div>
      <ul className="dropdown-plan-list">
        <li>
          <div
            className={
              selectedPlan === "All" ? "plan-list active" : "plan-list"
            }
            onClick={() => handlePlanChange("All")}
          >
            All
          </div>
        </li>
        <li>
          <div
            className={
              selectedPlan === "Monthly" ? "plan-list active" : "plan-list"
            }
            onClick={() => handlePlanChange("Monthly")}
          >
            Monthly
          </div>
        </li>
        <li>
          <div
            className={
              selectedPlan === "Yearly" ? "plan-list active" : "plan-list"
            }
            onClick={() => handlePlanChange("Yearly")}
          >
            Yearly
          </div>
        </li>
      </ul>
    </div>
  );

  const Status = (
    <div>
      <ul className="dropdown-plan-list">
        <li>
          <div
            className={
              selectedStatus === "All" ? "plan-list active" : "plan-list"
            }
            onClick={() => handleStatusChange("All")}
          >
            All
          </div>
        </li>
        <li>
          <div
            className={
              selectedStatus === "active" ? "plan-list active" : "plan-list"
            }
            onClick={() => handleStatusChange("active")}
          >
            Active
          </div>
        </li>
        <li>
          <div
            className={
              selectedStatus === "canceled" ? "plan-list active" : "plan-list"
            }
            onClick={() => handleStatusChange("canceled")}
          >
            Inactive
          </div>
        </li>
        <li>
          <div
            className={
              selectedStatus === "paused" ? "plan-list active" : "plan-list"
            }
            onClick={() => handleStatusChange("paused")}
          >
            Paused
          </div>
        </li>
      </ul>
    </div>
  );

  return (
    <>
      <div id="wrapper" className={DrawerValue ? "" : "sb-sidenav-toggled"}>
        <Drawer />
        <div id="content-wrapper" className="d-flex flex-column customDashbard">
          <div
            id="content"
            onClick={() => {
              if (!DrawerValue) {
                dispatch(setDrawerClose(true)); // Set DrawerValue to true
              }
            }}
          >
            <Navbar />
            <div className="subscription-block">
              <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-3">
                  <h1 className="page-title">Subscriptions</h1>
                </div>
                <div className="dashboard-filter mb-4">
                  <form>
                    <label className="search-label">Email ID</label>
                    <div className="search-feild">
                      <input
                        type="text"
                        value={userEmail}
                        placeholder="Enter email address"
                        onChange={handleInputChange}
                      />
                      <img
                        alt="SearchIcon"
                        src={SearchIcon}
                        className="search-icon"
                      />
                    </div>
                  </form>
                </div>
                <div className="custom-table mb-3">
                  <div className="table-responsive" ref={tableRef}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S No.</th>
                          <th className="text-center">User Email</th>
                          <th className="text-center">Start Date</th>
                          <th className="text-center">End Date</th>
                          <th className="text-center">Account #</th>
                          <th className="pl-5">
                            <div className="custom-popover">
                              <Popover
                                onOpenChange={() => {
                                  setPopoverVisible(!popoverVisible);
                                  setPopoverVisibleForStatus(false);
                                }}
                                content={Plans}
                                placement="bottom"
                                open={popoverVisible}
                                trigger="click"
                                className="sdddddddd"
                              >
                                Plan <DownArrow />
                              </Popover>
                            </div>
                            {/* <div className="dropdown plan-dropdown">
                              <button
                                className="btn btn-outline dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Plan <DownArrow />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li>
                                  <button
                                    className={
                                      selectedPlan === "All"
                                        ? "dropdown-item active"
                                        : "dropdown-item"
                                    }
                                    onClick={() => handlePlanChange("All")}
                                  >
                                    All
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className={
                                      selectedPlan === "Monthly"
                                        ? "dropdown-item active"
                                        : "dropdown-item"
                                    }
                                    onClick={() => handlePlanChange("Monthly")}
                                  >
                                    Monthly
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className={
                                      selectedPlan === "Yearly"
                                        ? "dropdown-item active"
                                        : "dropdown-item"
                                    }
                                    onClick={() => handlePlanChange("Yearly")}
                                  >
                                    Yearly
                                  </button>
                                </li>
                              </ul>
                            </div> */}
                          </th>
                          <th className="text-center">Method</th>
                          <th className="text-center">
                            <div className="custom-popover">
                              <Popover
                                content={Status}
                                onOpenChange={() => {
                                  setPopoverVisible(false);
                                  setPopoverVisibleForStatus(
                                    !popoverVisibleForStatus
                                  );
                                }}
                                open={popoverVisibleForStatus}
                                placement="bottom"
                                trigger="click"
                                className="sdddddddd"
                              >
                                Status <DownArrow />
                              </Popover>
                            </div>
                            {/* <div className="dropdown plan-dropdown">
                              <button
                                className="btn btn-outline dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Status <DownArrow />
                              </button>
                              <ul
                                className="dropdown-menu "
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li>
                                  <a
                                    className={
                                      selectedStatus === "All"
                                        ? "dropdown-item active"
                                        : "dropdown-item"
                                    }
                                    onClick={() => handleStatusChange("All")}
                                  >
                                    All
                                  </a>
                                  <a
                                    className={
                                      selectedStatus === "active"
                                        ? "dropdown-item active"
                                        : "dropdown-item"
                                    }
                                    onClick={() => handleStatusChange("active")}
                                  >
                                    Active
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className={
                                      selectedStatus === "canceled"
                                        ? "dropdown-item active"
                                        : "dropdown-item"
                                    }
                                    onClick={() =>
                                      handleStatusChange("canceled")
                                    }
                                  >
                                    Inactive
                                  </a>
                                </li>
                              </ul>
                            </div> */}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedData.length > 0 ? (
                          paginatedData.map((user, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {(currentPage - 1) * itemsPerPage + index + 1}
                                </td>
                                <td className="text-center">
                                  {user?.customer_email}
                                </td>
                                <td className="text-center">
                                  {user?.start_date}
                                </td>
                                <td className="text-center">
                                  {user?.end_date}
                                </td>
                                <td className="text-center">
                                  {user?.account_id}
                                </td>
                                <td className="pl-5">
                                  <div className="plan-name">
                                    {user?.subscription
                                      ? user?.subscription
                                      : "N/A"}
                                    <span className="plan-discount">
                                      {user?.discount_name && user?.discount_name !== "No coupon applied"
                                        ? user?.discount_name
                                        : ""}
                                    </span>
                                  </div>
                                </td>
                                <td className="text-center">
                                  {user?.payment_method_details?.brand
                                    ? user.payment_method_details.brand
                                    : user?.payment_method_details?.type}
                                </td>
                                <td className="text-center">
                                  <span
                                    className={
                                      user?.subscription_status === "active"
                                        ? "status-active"
                                        : "status-inactive"
                                    }
                                  >
                                    {user.subscription_status === "active"
                                      ? "Active"
                                      : user.subscription_status === "paused"
                                      ? "Paused"
                                      : "Inactive"}
                                  </span>
                                </td>
                              </tr>
                            );
                          })
                        ) : paginatedData.length === 0 && datalaoder ? (
                          <tr>
                            <td colSpan="8">
                              <div className="no-data">No data found</div>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td colSpan="8">
                              <div
                                className="no-data"
                                style={{ position: "relative", height: "43vh" }}
                              >
                                <Loader />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {filteredData.length > 0 && (
                  <Pagination
                    current={currentPage}
                    pageSize={itemsPerPage}
                    total={filteredData.length}
                    onChange={handlePageChange}
                    showSizeChanger
                    pageSizeOptions={["10", "20", "50"]}
                    className="justify-content-end mb-3"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </>
  );
};

export default Subscriptions;
